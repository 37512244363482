import { createSlice } from "@reduxjs/toolkit";
import { AIModelList } from "../Components/AIModelSelect";

const initialState = {
  user: {},
  isSettingEnabled: false,
  isClearChatData: false,
  myFileList: 0,
  isTyping: false,
  selectedAIModel: AIModelList[2],
  vectorTypeDB: "chromadb",
  isNewChatModel: false,
  isRFormate: false,
  temperature: 0.7,
  chatHistory: [
    // {
    //   id: 1696479115370,
    //   text: "bank",
    //   sender: "user",
    // },
    // {
    //   id: 1696479127124,
    //   text: "# Bank\n\nA bank is a financial institution that provides various services to individuals, businesses, and governments. It plays a crucial role in the economy by facilitating the flow of funds and providing a range of financial products and services. Here are some key points about banks:\n\n## Types of Banks\n\n1. **Commercial Banks**: These are the most common type of banks that provide a wide range of services to individuals and businesses. They accept deposits, offer loans, provide investment services, and facilitate electronic transactions.\n\n2. **Retail Banks**: Also known as consumer banks, retail banks primarily serve individual customers. They offer services like savings accounts, checking accounts, mortgages, personal loans, credit cards, and other retail banking products.\n\n3. **Investment Banks**: Investment banks focus on providing financial services to corporations, institutional investors, and governments. They assist in raising capital through underwriting, mergers and acquisitions, and financial advisory services.\n\n4. **Central Banks**: Central banks are responsible for regulating and controlling the country's money supply and monetary policy. They act as the lender of last resort, maintain price stability, and supervise the banking sector.\n\n5. **Online Banks**: Online banks operate solely through online platforms, with no physical branchesc.",
    //   sender: "bot",
    // },
  ],
  pipelineTypeDoc: "openaiFile",
  typeOfAg: "default",
  flowPipeline: "langGraph",
  agentList: [],
  isChatIcon: true,
  isTypingEffect: true,
};

export const rootReducerSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setIsSettingEnabled: (state, { payload }) => {
      state.isSettingEnabled = payload;
    },
    setIsClearChatData: (state, { payload }) => {
      state.isClearChatData = payload;
    },
    setMyFileList: (state, { payload }) => {
      state.myFileList = payload;
    },
    setIsTypingFN: (state, { payload }) => {
      state.isTyping = payload;
    },
    setSelectedAIModel: (state, { payload }) => {
      state.selectedAIModel = payload;
    },
    setIsNewChatModel: (state, { payload }) => {
      state.isNewChatModel = payload;
    },
    setVectorTypeDB: (state, { payload }) => {
      state.vectorTypeDB = payload;
    },
    setIsRFormate: (state, { payload }) => {
      state.isRFormate = payload;
    },
    setTypeOfAg: (state, { payload }) => {
      state.typeOfAg = payload;
    },
    setTemperature: (state, { payload }) => {
      state.temperature = payload;
    },
    setChatHistory: (state, { payload }) => {
      state.chatHistory = payload;
    },
    setPipelineTypeDoc: (state, { payload }) => {
      state.pipelineTypeDoc = payload;
    },
    setFlowPipeline: (state, { payload }) => {
      state.flowPipeline = payload;
    },
    setAgentList: (state, { payload }) => {
      state.agentList = payload;
    },
    setIsChatIcon: (state, { payload }) => {
      state.isChatIcon = payload;
    },
    setIsTypingEffect: (state, { payload }) => {
      state.isTypingEffect = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUser,
  setIsSettingEnabled,
  setIsClearChatData,
  setMyFileList,
  setIsTypingFN,
  setSelectedAIModel,
  setIsNewChatModel,
  setVectorTypeDB,
  setIsRFormate,
  setTemperature,
  setChatHistory,
  setPipelineTypeDoc,
  setTypeOfAg,
  setFlowPipeline,
  setAgentList,
  setIsChatIcon,
  setIsTypingEffect,
} = rootReducerSlice.actions;

export default rootReducerSlice.reducer;
